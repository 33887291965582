// import '.asset/App.css';

function Articles() {
    return (
      <div className="App">
          <div className=''>
              <h1>Articles</h1>
          </div>
      </div>
    );
  }
  
  export default Articles;
  
import '../assets/styles/index.scss';
import '../assets/styles/project.scss';
import Project from "../data/projects";

function Projects() {
    return (
        <div className='wd-8 pt-10 pb-14 px-4'>
            <h1 className='pb-5'>Completed projects</h1>

            <div className='row mt-4 gx-8 gy-7'>
                {Project.map((project, index) => (
                    <div key={index} className='col-12 col-md-6'>
                        <div className="card">
                            <img
                                className="card__background"
                                src={`${project.thumbnail_img}`}
                                alt=""
                            />
                            <div className="card__content | flow">
                                <div className="card__content--container | flow">
                                <h2 className="card__title">{project.title}</h2>
                                <p className="card__description">
                                    {project.description}
                                </p>
                                </div>
                                <a target='_blank' rel='noreferrer' href={project.url} className="card__button">View more</a>
                            </div>
                        </div>
                        
                    </div>
                ))}
            </div>
        </div>
    );
  }
  
export default Projects;
  
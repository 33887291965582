import './assets/styles/index.scss';
import Layout from './components/Layout';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Projects from './pages/Projects';
import Articles from './pages/Articles';

function App() {

    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route exact path="/" element={<Home />}> </Route>
                <Route path="/projects" element={<Projects />}>Projects</Route>
                <Route path="/articles" element={<Articles />}>Articles</Route>
                <Route path="/contact" element={<Articles />}>Contact</Route>
                <Route path="*" element={<NotFound />}></Route>
            </Route>
        </Routes>
    );
}

export default App;

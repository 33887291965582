import '../assets/styles/index.scss';

function Home() {
    return (
        <div className='Home'>
            <div className='wd-8 px-4 px-md-14'>
                <h1 className='pb-2 fs-2 d-flex flex-column d-md-block hero-title' style={{ lineHeight: '1.5' }}><span>I AM </span> INIOBONG PIUS</h1>
                <p className='lh-lg pb-1 fs-7'>
                    I am a dedicated frontend developer who thrives on creating seamless, responsive, and engaging web applications. My passion lies in transforming complex problems into intuitive user interfaces, always aiming to deliver the best user experience.
                </p>
                <p className='lh-lg fs-7'>
                    When I'm not coding, you'll find me immersed in reading or writing to share my knowledge with others. I'm driven by a relentless passion for growth, always seeking to learn new technologies and best practices to stay ahead in the ever-evolving tech landscape.
                </p>
                
                <div className='mt-6'>
                    <p>Let's connect :</p>
                    <div className='d-flex gap-7'>
                        <a className='social-icon' href='https://www.linkedin.com/in/piusiniobong/' rel="noreferrer" target='_blank'>
                            <img width={17} src={require('./../assets/images/linkedin.svg').default} alt='logo-icon' />
                        </a>
                        <a className='social-icon'  href='https://twitter.com/PiusIniobong' rel="noreferrer" target='_blank'>
                            <img width={17} src={require('./../assets/images/twitter.svg').default} alt='logo-icon' />
                        </a>
                        <a className='social-icon'  href='https://hashnode.com/@pye' rel="noreferrer" target='_blank'>
                            <img width={17} src={require('./../assets/images/hashnode.svg').default} alt='logo-icon' />
                        </a>
                        <a className='social-icon'  href='https://github.com/payose' rel="noreferrer" target='_blank'>
                            <img width={17} src={require('./../assets/images/github.svg').default} alt='logo-icon' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
  
export default Home;
  
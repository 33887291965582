import '../assets/styles/header.scss';
import { Link } from 'react-router-dom';

function Home() {
    window.addEventListener('scroll', function() {
        const header = document.getElementById('header');
        if (window.scrollY > 40) {
          header.classList.add('scrolled');
        } else {
          header.classList.remove('scrolled');
        }
    });

      
    return (
        <header id='header'>
            <div className=''>
                <Link to="/">
                    <img className='logo' src={require('./../assets/images/shapes.svg').default} alt='logo-icon' />
                </Link>
                <input type="checkbox" id="click"></input>
                <label for="click" className="menu-btn">
                    <img className='' src={require('./../assets/images/open_menu.svg').default} alt='open-icon' />
                </label>

                <ul className="nav__links">
                    <li>
                        <input type="checkbox" id="click"></input>
                        <label for="click" className="menu-btn">
                            <img className='' src={require('./../assets/images/close.svg').default} alt='close-icon' />
                        </label>
                    </li>
                    <li><Link to="/projects" className=''>Projects</Link> </li>
                    <li className=''>
                        <a href='https://pye.hashnode.dev/' rel='noreferrer' target='_blank'  className='d-flex align-items-center' style={{ gap: '5px' }}>Blog 
                        <span className='mt-0'>
                            <svg width={11} height={11} stroke-width="1"  fill="none" stroke="#060a0ba4" viewBox="0 0 12 12" aria-hidden="true"><path d="M10.976 1.193A.314.314 0 0010.687 1H6.312a.313.313 0 000 .625h3.62L5.467 6.091a.313.313 0 00.443.442l4.466-4.466v3.62a.313.313 0 00.625 0V1.313a.328.328 0 00-.024-.119z"></path><path d="M3.5 1v.625H2.25a.625.625 0 00-.625.625v7.5c0 .345.28.625.625.625h7.5c.345 0 .625-.28.625-.625V8.5H11v1.875c0 .345-.28.625-.625.625h-8.75A.625.625 0 011 10.375v-8.75C1 1.28 1.28 1 1.625 1H3.5z"></path></svg>
                            </span>
                        </a>
                    </li>
                    <li><Link to="/" className=''>Contact</Link> </li>
                </ul>
            
            </div>
		</header>
    );
  }
  

  export default Home;
  
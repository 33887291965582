import { Outlet } from "react-router-dom"
import React from "react"
import Header from './Header';
// import Footer from "./Footer";


const Layout = () => {
  return (
    <main>
      <Header/>
      <div className="app-padding-top">
        <Outlet/>
      </div>
      {/* <Footer/> */}
    </main>
  )
}

export default Layout
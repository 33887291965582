// import '.asset/App.css';

function NotFound() {
    return (
      <div className="App">
          <div className=''>
              <h1>404</h1>
              <h1>Page not found</h1>
              <p>
                The page you are looking for doesn't exist or has been moved.
            </p>
              
          </div>
      </div>
    );
  }
  
  export default NotFound;
  